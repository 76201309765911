import "./Intro.css"
import ScrollService from "../../../Utilities/ScrollService"

function Intro() {
    return (
        <div className='intro-container'>
            <div className='intro-left-container'>
                <div className="intro-blur">
                    <div className='intro-text-container'>
                        <p>El Centro Médico Health Center está orientado a resolver problemas de salud ambulatorios. La conformación de un Centro Médico nace de la idea de agrupar a especialistas médicos de reconocida ética y capacidad profesional, con espíritu de constante superación y respeto por los valores humanos y sociales.</p>
                        <p>Una atención médica integral es el primer objetivo que nos marcamos día a día, con la capacidad para dar respuesta a las necesidades asistenciales de nuestros pacientes.</p>
                        <p>Contamos con una adecuada infraestructura, tecnología de punta y personal altamente capacitado para brindarles a nuestros pacientes la mejor atención.</p>
                        <p>El círculo de confianza se cierra con una tecnología de vanguardia. El Centro cuenta con los medios más avanzados del mercado, un espacio equipado con avanzada tecnología y un servicio de excelencia para los pacientes.</p>
                    </div>
                    <button className="btn primary-btn" onClick={() => ScrollService.scrollHandler.scrollToContact()}>
                        Contáctanos
                    </button>  
                </div>
            </div>
            <div className="logo-container">
                <div className="logo-big"></div>
            </div>
            
        </div>
    )
}

export default Intro
