import SubNav from "../Utilities/SubNav/SubNav"
import logosmall from "../Assets/Specialty/MEDsmall.png"
import MedServices from "../Components/Services/MedServices"

const navBack = {color:'#e05591'}

function MedEstetica() {
    return (
        <div>
            <SubNav color={navBack} logo={logosmall} />
            <MedServices />
        </div>
        
    )
}

export default MedEstetica
