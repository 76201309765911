import "./Contact.css"

function Contact(props) {

  const scrollHome = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    })
  }

  return (
    <div className="contact-container" id={props.id || ""}>
      <div className='contact-wide'>
            <div className='contact-left-container'>
                <div className="contact-title-container">
                    <div className='contact-title'>
                        <span>Contáctanos</span>
                    </div>
                    <div className='contact-title-separator'>
                        <div className='contact-separator-line'></div>
                    </div>
                </div>
                <div className='contact-content-container'>
                    <div className='contact-content-text'>
                        <div className="contact-address-title"> 
                            <span>Health Center - Centro Médico</span>
                        </div>                   
                        <div className="contact-text">
                            <p>Av. Arce esquina plaza Isabel a Católica</p>
                            <p>Edif. Torres del Poeta</p>
                            <p>Torre B Piso 5 Of. 504</p>
                            <p>La Paz - Bolivia</p>
                            <p>healthcenter.centromedico@outlook.com</p>
                        </div>
                        <div className="contact-text">
                            <p>Horarios de Atención de Lunes a Viernes:</p>
                            <p>Mañana: 08:00 - 12:00</p>
                            <p>Tarde: 15:00 - 20:00</p>
                        </div>
                    </div>
                    <div className='contact-buttons'> 
                        <button className="btn-email" onClick={(e) => {
                            e.preventDefault()
                            window.location.href='mailto:healthcenter.centromedico@outlook.com'
                        }}>
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                            <span>Escríbenos</span>
                        </button>
                        <button className="btn-phone" onClick={(e) => {
                            e.preventDefault()
                            window.location.href='tel:72533440'
                        }}>
                            <i class="fa fa-phone" aria-hidden="true"></i>
                            <div className='btn-phone-text'>
                                <span>Llámanos</span>
                                <span className='btn-phone-num'>+591 72533440</span>
                            </div>
                        </button>
                        <button className="btn-map" onClick={(e) => {
                            e.preventDefault()
                            window.open('https://goo.gl/maps/yuWdDDW9XhxDSZUP7')
                        }}>
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            <span>Encuéntranos</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className='contact-right-container'>
                <div className='contact-image'></div>
            </div>
        </div>
        <div className="footer-scroll-container">
            <button className="btn-scroll" onClick={scrollHome}> 
                <i className="fa fa-chevron-up"></i>
            </button>
        </div>
    </div>
  )
} 

export default Contact