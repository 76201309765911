import Intro from './Intro/Intro'
import Header from './Header/Header'

function Home() {
    return (
        <div>
            <Header />
            <Intro />
        </div>
    )
}

export default Home
