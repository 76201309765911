import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ScrollToTop from "./Utilities/ScrollToTop"

//Pages
import MainPage from './Pages/MainPage'
import FacialCenter from './Pages/FacialCenter'
import MedEstetica from './Pages/MedEstetica'
import MedicalCenter from './Pages/MedicalCenter'
import OralCenter from './Pages/OralCenter'

function App() {
  return <Router>
    <ScrollToTop />
    <Routes>
      <Route exact path='/' element={<MainPage />}/>
      <Route exact path='/FacialCenter' element={<FacialCenter />}/>
      <Route exact path='/MedEstetica' element={<MedEstetica />}/>
      <Route exact path='/MedicalCenter' element={<MedicalCenter />}/>
      <Route exact path='/OralCenter' element={<OralCenter />}/>
    </Routes>
  </Router>    
}

export default App
