import SubNav from "../Utilities/SubNav/SubNav"
import logosmall from "../Assets/Specialty/ORALsmall.png"
import OralServices from "../Components/Services/OralServices"

const navBack = {color:'#0578bd'}

function OralCenter() {
    return (
        <div>
            <SubNav color={navBack} logo={logosmall} />
            <OralServices />
        </div>
        
    )
}

export default OralCenter
