import Components from '../Components/Components'
import Footer from '../Components/Footer/Footer'

function Main() {
  return (
    <div className="mainpage">
        <Components />
        <Footer />
    </div>
  )
}

export default Main