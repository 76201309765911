import Titles from "../../Utilities/Titles/Titles"
import "./MedicalServices.css"

function MedicalServices(props) {
    return (
        <div className="medical-container">
            <div className="fc-content">
                <div className='fc-content-top'>
                    <div className='mc-content-image'></div>
                    <div className="fc-content-bio">
                        <span>Dr. L. Gonzalo Vargas A.</span>
                        <ul>
                            <li>Especialista en Medicina Interna</li>
                            <li>Miembro de la Sociedad Boliviana de Medicina Interna</li>
                            <li>Master en Emergencias</li>
                            <li>Docente instructor de pre y postgrado</li>
                            <li>Docente Instructor Basic Life Support y Advanced Cardiovascular Life Support de la American Heart Association</li>
                        </ul>
                        <p>La medicina interna es una de las especialidades de la medicina y se dedica al estudio, diagnóstico y tratamiento de las enfermedades del adulto; los tratamientos que ofrece no son quirúrgicos. Esta disciplina estudia al paciente de forma integral y ve al ser humano de forma holística. Es decir, ve todos los sistemas y el funcionamiento de cada órgano con el objetivo de preservar la salud de los pacientes.</p>
                        <p>Atiende integralmente patologías del  adulto, patologías neumológicas, cardiovasculares - hipertensión arterial, endocrinológicas, metabólicas, gastroenterológicas, reumatológicas, infecciosas, inmunológicas. Valoración preoperatoria.</p>
                    </div>
                </div>
            </div>

            <div className="fc-services">
                <div className="mc-services-title">
                    <Titles title={"Servicios"} />
                </div>
                <div className='fc-services-container'>
                    <div className='mc-services-bullets'>
                        <ul>
                            <li><span>Patología médica general: </span>valoraciones, síndromes generales, factores de riesgo cardiovascular (hipertensión arterial, diabetes, dislipemia).</li>
                            <li>Pacientes pluripatológicos y geriátricos.</li>
                            <li>Patología funcional y psicosomática, cuidados paliativos.</li>
                            <li>Enfermedades autoinmunes y sistémicas.</li>
                            <li>Enfermedades infecciosas.</li>
                            <li>Patología cardiorrespiratoria, gastroenterológica, reumatológica, metabólico-endocrina.</li>
                            <li>Estudios preoperatorios, electrocardiograma.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MedicalServices
