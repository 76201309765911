import SubNav from "../Utilities/SubNav/SubNav"
import logosmall from "../Assets/Specialty/FACIALsmall.png"
import FacialServices from "../Components/Services/FacialServices"
import "../Components/Services/FacialServices.css"

const navBack = {color:'#4cc8bc'}

function FacialCenter() {
    return (
        <div>
            <SubNav color={navBack} logo={logosmall} />
            <FacialServices />
        </div>
        
    )
}

export default FacialCenter
