import Titles from "../../Utilities/Titles/Titles"
import { Link } from 'react-router-dom'
import "./Specialty.css"

function Specialty(props) {
    return (
        <div className="SP-container fade-in" id={props.id || ""}>
            <div className="SP-title">
                <Titles title={"Especialidades"} />
            </div>
            <div className='SP-content-container'>
                <div className='SP-left'>
                    <div className='SP-image1-container'>
                        <Link to='/FacialCenter'><div className="SP-image1"></div></Link>
                    </div>
                    <div className='SP-image2-container'>
                    <Link to='/MedEstetica'><div className="SP-image2"></div></Link>                   
                    </div>
                </div>
                <div className='SP-right'>
                    <div className='SP-image3-container'>
                        <Link to='/MedicalCenter'><div className="SP-image3"></div></Link>
                    </div>
                    <div className='SP-image4-container'>
                        <Link to='/OralCenter'><div className="SP-image4"></div></Link> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Specialty
