import SubNav from "../Utilities/SubNav/SubNav"
import logosmall from "../Assets/Specialty/MEDICALsmall.png"
import MedicalServices from "../Components/Services/MedicalServices"

const navBack = {color:'#1d3875'}

function MedicalCenter() {
    return (
        <div>
            <SubNav color={navBack} logo={logosmall} />
            <MedicalServices />
        </div>
        
    )
}

export default MedicalCenter


