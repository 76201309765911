import Titles from "../../Utilities/Titles/Titles"
import "./OralServices.css"

function FacialServices() {
    return (
        <div className="oral-container">
            <div className="fc-content">
                <div className='fc-content-top'>
                    <div className='oc-content-image'></div>
                    <div className="fc-content-bio">
                        <p>La odontología es la rama de las ciencias de la salud que se encarga del estudio, diagnóstico, prevención y tratamiento de las enfermedades de los dientes y, en general, de aquellas que afectan al aparato estomatognático.</p>
                        <p>La odontología no se limita a intervenir en las enfermedades de los dientes, sino que también abarca todo lo que compone el aparato estomatognático, compuesto por los dientes, la cavidad oral, los maxilares, los músculos, la piel, los vasos y los nervios de esa parte del cuerpo.</p>
                        <p>Nuestra Clínica Odontológica, cuenta con una amplia serie de especialidades odontológicas, para un diagnóstico y tratamiento correcto desde el comienzo de la consulta dental.</p>
                    </div>
                </div>
            </div>

            <div className="fc-services">
                <div className="oc-services-title">
                    <Titles title={"Servicios"} />
                </div>
                <div className='fc-services-container'>
                    <div className='oc-services-bullets'>
                        <span>Odontología General</span>
                        <p>La odontología general es la que se encarga de solucionar los problemas primarios de los dientes y derivarlos al especialista adecuado en el caso de necesitar tratamientos posteriores.</p>
                        <span>Ortodoncia</span>
                        <p>Especialidad encargada del estudio, prevención, diagnóstico y tratamiento de anomalías entre huesos y dientes, permitiendo mejoras en la forma, posición y función de ellos.</p>
                        <span>Endodoncia</span>
                        <p>Especialidad encargada de la limpieza, desinfección y remoción del tejido nervioso dañado, en una pieza dental. Su objetivo es salvar y/o reparar la pieza dañada por caries o algún otro daño.</p>
                        <span>Odontopediatría</span>
                        <p>Especialidad encargada de la atención dental adecuada en niños, a través de técnicas preventivas que ayudarán a un crecimiento sin caries y eventualmente tratar otras patologías presentes.</p>
                        <span>Periodoncia</span>
                        <p>Especialidad encargada del cuidado de las encías y hueso para mantener los dientes firmes en boca. Se trata a través de limpieza y remoción de sarro entre la encía y diente, logrando así la salud de las encías.</p>
                        <span>Rehabilitación Oral</span>
                        <p>Especialidad encargada de devolver la estética y funcionalidad a los dientes de las personas mediante coronas, carillas, incrustaciones, prótesis removibles, puentes y coronas sobre implantes.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FacialServices
