import Titles from "../../Utilities/Titles/Titles"
import "./About.css"

function About(props) {
  return (
    <div className="about-container" id={props.id || ""}>
      <div className="about-title">
          <Titles title={"¿Quiénes somos?"} />
      </div>
      <div className='about-content-container'>
            <div className='about-image-container'>
                <div className="about-image"></div>                   
            </div>
            <div className="about-text-container">
              <span className='intro-bigline'>Centro de atención ambulatoria de múltiples especialidades</span>
              <ul>
                <li>Equipo médico de excelencia.</li>
                <li>Tecnología de última generación.</li>
                <li>Áreas de atención divididas por especialidad.</li>
                <li>Sistema digitalizado de seguimiento de pacientes, para brindar un servicio eficaz y eficiente.</li>
                <li>Instalaciones de primera calidad.</li>
              </ul>
              <span className='intro-subline'>MISIÓN</span>
              <p>Ofrecer servicios sin internación en el ámbito de la salud de los pacientes que nos elijan de acuerdo a unos valores y principios éticos pensados para contribuir a su máximo bienestar y pronta recuperación médica.</p>
              <span className='intro-subline'>VISIÓN</span>
              <p>Ser una empresa líder en salud, atención a los pacientes de excelencia. Con el reconocimiento de sus pacientes por los cuidados recibidos y por el de sus profesionales en el servicio prestado.</p>
              <span className='intro-subline'>VALORES</span>
              <p>Orientación en el desarrollo de actividades relacionadas con la salud a largo plazo. Vocación de servicio buscando continuamente los mejores resultados de calidad y seguridad de los pacientes. Creación de valor por el buen hacer gracias a la profesionalidad de nuestro trabajo realizado con disciplina y esfuerzo.</p>
              <span className='intro-subline'>OBJETIVOS</span>
              <p>Mejora continua en nuestra forma de trabajo y compromiso con los pacientes con ética laboral, integridad y honestidad así como con el cumplimiento de la legislación aplicable.</p>
            </div>
        </div>
    </div>    
  )
} 

export default About