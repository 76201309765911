import Titles from "../../Utilities/Titles/Titles"
import "./MedServices.css"

function MedServices() {
    return (
        <div className="med-container">
            <div className="fc-content">
                <div className='fc-content-top'>
                    <div className='me-content-image'></div>
                    <div className="fc-content-bio">
                        <span>Dra. M. Silvia Ayala Valencia</span>
                        <ul>
                            <li>Médico Especialista en Medicina estética</li>
                            <li>Maestría en Hematología y hemoterapia (PRP)</li>
                            <li>Especialidad en Patología Clínica</li>
                            <li>Miembro Titular de la Asociación Boliviana de Medicina Estética  ASOBOME  / UIME (Unión Internacional de Medicina Estética)</li>
                            <li>Jefe de Servicio Transfusional del Hospital  Materno Infantil CNS</li>
                            <li>Cirujano Maxilofacial: Hospital Militar Central</li>
                            <li>Docente instructor de pre y postgrado</li>
                        </ul>
                        <p>La medicina estética es una rama de la medicina, que tiene  como objetivo: la restauración, mantenimiento y promoción de  la “salud   y la estética",  se centra en los tratamientos para la mejoría estética, el rejuvenecimiento.</p>
                        <p>En Health Center  se realizan integralmente tratamientos en medicina estética, regenerativa, antiage, ozonoterapia, procedimientos  estéticos faciales y corporales, mediante procedimientos ambulatorios (sin internación).</p>
                    </div>
                </div>
            </div>

            <div className="fc-services">
                <div className="me-services-title">
                    <Titles title={"Servicios"} />
                </div>
                <div className='fc-services-container'>
                    <div className='fc-services-text'>
                        <p>Atención profesional en medicina estética, regenerativa, antienvejecimiento, ozonoterapia,  rejuvenecimiento facial, plasma rico en plaquetas, mesoterapia, radiofrecuencia, ácido hialurónico, botox, manejo de las secuelas de acné.</p>
                    </div>
                    <div className='fc-services-bullets'>
                        <ul>
                            <li>Rejuvenecimiento facial</li>
                            <li>Plasma Rico en Plaquetas</li>
                            <li>Toxina botulínica</li>
                            <li>Ácido Hialurónico</li>
                            <li>Tratamiento</li>
                            <li>Hilos tensores</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MedServices
