import Home from '../Components/Home/Home'
import About from '../Components/About/About'
import Specialty from '../Components/Specialty/Specialty'
import Contact from '../Components/Contact/Contact'

export const TOTAL_SCREENS = [
    {
        screen_name:"Inicio",
        component: Home,
    },
    {
        screen_name:"¿Quiénes somos?",
        component: About,
    },
    {
        screen_name:"Especialidades",
        component: Specialty,
    },
    {
        screen_name:"Contáctanos",
        component: Contact,
    },
]

export const GET_SCREEN_INDEX = (screen_name) => {
    if(!screen_name) return -1
    for (let i=0; i< TOTAL_SCREENS.length;i++) {
        if(TOTAL_SCREENS[i].screen_name === screen_name) return i
    }
    return -1
}