import './Titles.css'

function Titles(props) {
    return (
        <div className='title-container'>
            <div className='title'>
                <span>{props.title}</span>
            </div>
            <div className='title-separator'>
                <div className='separator-line'></div>
            </div>
        </div>
    )
}

export default Titles
