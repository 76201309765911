import { Link } from 'react-router-dom'

import './SubNav.css'

function SubNav(props) {
    return (
        <div className="nav-container">
            <Link to='/'>
                <i className="nav-back fa fa-chevron-left" style={props.color}></i>
            </Link>
            <div className="nav-logo-container">
                <img className="nav-logo-small" src={props.logo} alt="Logo" />
            </div>
                
        </div>
    )
}

export default SubNav
