import Titles from "../../Utilities/Titles/Titles"
import "./FacialServices.css"

function FacialServices() {
    return (
        <div className="facial-container">
            <div className="fc-content">
                <div className='fc-content-top'>
                    <div className='fc-content-image'></div>
                    <div className="fc-content-bio">
                        <span>Dra. Andrea Vargas Ayala</span>
                        <ul>
                            <li>Médico Cirujano</li>
                            <li>Cirujano Odontólogo</li>
                            <li>Especialista en Cirugía Oral y Maxilofacial</li>
                            <li>Miembro titular de la Sociedad Boliviana de Cirugía Bucomaxilofacial</li>
                            <li>Miembro de la Asociación Latinoamericana de Cirugía Bucal y Maxilofacial</li>
                            <li>Cirujano Maxilofacial: Hospital Militar Central</li>
                            <li>Docente instructor en pre y postgrado</li>
                        </ul>
                        <p>La Cirugía Oral y Maxilofacial es una especialidad médica que se dedica al estudio, prevención, diagnóstico, tratamiento quirúrgico y rehabilitación de enfermedades congénitas y adquiridas del cráneo, cara, cabeza, cavidad oral, maxilares, articulación temporomandibular, glándulas salivales y cuello. Esta especialidad abarca un gran espectro de enfermedades, heridas, lesiones y aspectos estéticos de la boca, dientes, cara, cabeza y cuello.</p>
                    </div>
                </div>
            </div>

            <div className="fc-services">
                <div className="fc-services-title">
                    <Titles title={"Servicios"} />
                </div>
                <div className='fc-services-container'>
                    <div className='fc-services-text'>
                        <p>Al trabajar en un territorio anatómico muy complejo, la especialidad abarca una gran variedad de procedimientos.</p>
                    </div>
                    <div className='fc-services-bullets'>
                        <ul>
                            <li><span>Cirugía bucal: </span>Extracciones simples, complejas y de terceros molares (muelas del juicio).</li>
                            <li>Cirugías reconstructivas del territorio bucal y maxilofacial.</li>
                            <li><span>Resolución del trauma facial: </span>fracturas faciales, mandibulares, trauma dentoalveolar.</li>
                            <li><span>Patologías de glándulas salivales: </span> quistes, tumores benignos, cálculos, etc.</li>
                            <li><span>Patología infecciosa cervicofacial: </span>celulitis, flegmones y abscesos bucales, faciales y cervicales.</li>
                            <li><span>Alteraciones de la articulación temporomandibular: </span> disfunción temporomandibular, osteoartritis, reabsorción condilar, anquilosis de articulación.</li>
                            <li><span>Implantología oral: </span>colocación y rehabilitación de implantes dentales osteointegrados.</li>
                            <li>Tumores de los huesos y tejidos blandos de la cavidad oral.</li>
                            <li>Deformidades craneofaciales.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FacialServices
